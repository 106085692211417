import { useEffect, useState } from "react";
import { getReferralsListAPI } from "../../api/api";
import { Box, TextField } from "@mui/material";
import { Loader } from "../../components";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";
import DownloadCSV from "../../shared/downloadCSV/DownloadCSV";
import { DatePicker } from "antd";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const ReferralsPage = () => {
	const [loading, setLoading] = useState(false);
	const [cData, setCData] = useState([]);
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const getAPI = async () => {
		setLoading(true);

		try {
			const res = await getReferralsListAPI();

			if (res.status === "success") {
				// convert res.data to array if it's an object
				if (res?.data && typeof res?.data === "object") {
					const data = Object.values(res?.data);
					setCData(data);
					setFilteredData(data);
				} else {
					setCData(res?.data);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAPI();
	}, []);

	const columns = [
		{ field: "id", headerName: "ID", width: 80, download: true },
		{
			field: "referrer_name",
			headerName: "Referrer Name",
			flex: 1,
			download: true,
		},
		{
			field: "ref_code",
			headerName: "referral code",
			flex: 1,
			download: true,
		},
		{
			field: "collegeName",
			headerName: "College Name",
			flex: 1,
			download: true,
		},
		{
			field: "customer_name",
			headerName: "Customer Name",
			flex: 1,
			download: true,
		},
		{
			field: "date_of_join",
			headerName: "Joining Date (EST)",
			renderCell: (params) => (
				<p className='text-center' title='yyyy-mm-dd time format EST'>
					{dayjs(params?.row?.date_of_join)
						?.tz("America/New_York")
						.format("YYYY-MM-DD") || "---"}
				</p>
			),
			flex: 1,
			download: true,
		},
		{
			field: "type",
			headerName: "Type",
			flex: 1,
			download: true,
		},
	];

	const handleSearch = () => {
		let filtered = cData;

		if (startDate === null && endDate === null) {
			setFilteredData(filtered);
			return;
		}

		if (dayjs(startDate).isValid()) {
			filtered = filtered.filter((item) => {
				const createdAt = dayjs(item.date_of_join).startOf("day");
				return createdAt.isSameOrAfter(dayjs(startDate).startOf("day"));
			});
		}
		if (dayjs(endDate).isValid()) {
			filtered = filtered.filter((item) => {
				const createdAt = dayjs(item.date_of_join).startOf("day");
				return createdAt.isSameOrBefore(dayjs(endDate).endOf("day"));
			});
		}

		setFilteredData(filtered);
	};

	useEffect(() => {
		handleSearch();
	}, [startDate, endDate, data]);

	return (
		<WrapperOutlet headingText={"Referrals Management"}>
			<div className='flex justify-end flex-col md:flex-row items-center gap-4'>
				<div className='flex gap-2'>
					<DatePicker
						label='Start Date'
						value={startDate}
						onChange={(newValue) => {
							setStartDate(newValue);
						}}
						slotProps={{ textField: { size: "small" } }}
						format='DD/MM/YYYY'
						renderInput={(params) => <TextField {...params} />}
					/>
					<DatePicker
						label='End Date'
						value={endDate}
						onChange={(newValue) => setEndDate(newValue)}
						slotProps={{ textField: { size: "small" } }}
						format='DD/MM/YYYY'
						renderInput={(params) => <TextField {...params} />}
					/>
				</div>
				<div className={`flex flex-row`}>
					<div className={"flex gap-2"}>
						<DownloadCSV
							fileName={"estu-referrals-report"}
							columns={columns}
							data={filteredData}
							fieldsToInclude={[
								"id",
								"referrer_name",
								"ref_code",
								"collegeName",
								"customer_name",
								"date_of_join",
								"type",
							]}
						/>
					</div>
				</div>
			</div>
			{loading ? (
				<div className='flex justify-center items-center h-64'>
					<div>
						Loading... <Loader />
					</div>
				</div>
			) : (
				<Box className='bg-white rounded-lg shadow-md p-4 flex-1'>
					<CDataGrid
						rows={filteredData.map((item, index) => {
							if (!item.hasOwnProperty("id")) {
								return { ...item, id: index };
							}
							return item;
						})}
						columns={columns}
						pagination
						loading={loading}
					/>
				</Box>
			)}
		</WrapperOutlet>
	);
};
export default ReferralsPage;
