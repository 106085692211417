import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import Snackbar from "../atoms/Snackbar";
import { Sidebar, ThemeSettings } from "..";
import Loader from "../atoms/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Layout = () => {
	const navigate = useNavigate();
	const {
		setCurrentColor,
		setCurrentMode,
		currentMode,
		menuToggle,
		themeSettings,
		isLoading,
	} = useStateContext();
	const [data, setData] = useState(null);
	const location = useLocation();
	const { userDepartment } = useAuth();
	const department = userDepartment();

	useEffect(() => {
		const currentThemeColor = localStorage.getItem("colorMode");
		const currentThemeMode = localStorage.getItem("themeMode");
		if (currentThemeColor && currentThemeMode) {
			setCurrentColor(currentThemeColor);
			setCurrentMode(currentThemeMode);
		}
	}, []);

	useEffect(() => {
		if (location.pathname === "/") {
			if (department === "MARKETING") {
				navigate("upload-csv");
			} else {
				navigate("/dashboard");
			}
		}
	}, [location.pathname, department, navigate]);

	if (isLoading) return <Loader />;

	const excludedPaths = [
		"/threads",
		"/waitlist",
		"/adsprofile",
		"/adsprofilecampagin",
		"/mbanq",
		"/non-ssn-student",
	];

	if (excludedPaths.includes(location.pathname)) {
		return (
			<div className={`${currentMode === "Dark" ? "dark" : ""}`}>
				<div className='flex relative dark:bg-main-dark-bg'>
					<Sidebar />
					<div className='w-full p-4'>
						<Outlet />
					</div>
				</div>
				<Snackbar />
			</div>
		);
	}
	return (
		<div className={`${currentMode === "Dark" ? "dark" : ""}`}>
			<div className='flex relative dark:bg-main-dark-bg'>
				{/* Sidebar */}
				<Sidebar />

				{/* Main Content */}
				<div
					className={`${
						menuToggle ? "m-from-toggled-sidebar-w" : "m-from-sidebar-w"
					} w-full  flex flex-col transition-all duration-300 pt-0 md:pt-0`}
				>
					{/* Theme Settings */}
					{themeSettings && <ThemeSettings />}

					{/* Page Content */}
					<div className='pt-3 bg-main-bg dark:bg-main-dark-bg flex-1 h-full'>
						<Outlet />
					</div>
				</div>
			</div>
			<Snackbar />
		</div>
	);
};

export default Layout;
